<template>
  <el-drawer title="自定义区域" append-to-body :visible.sync="show" :with-header="false" size="70%">
    <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :props="props" :load="loadNode" lazy @node-click="handleNodeClick" :style="{height: height + 'px', overflow: 'auto'}" />
  </el-drawer>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";
export default {
  mixins: [initData],
  props: {
    value: Number | String,
    type: String,
    visible: Boolean,
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      if (!val) this.$emit("update:visible", false);
    },
  },
  data() {
    return {
      data: null,
      props: {
        label: "name",
        isLeaf: "isLeaf",
      },
      show: false,
      height: "",
      treeIcons: {
        group: "fa fa-file-alt",
        company: "fa fa-file-alt",
        subcompany: "fa fa-file-alt",
        area: "fa fa-file-alt",
        shop: "fa fa-file-alt",
      },
    };
  },
  mounted() {
    this.loadInit();
    this.height = window.innerHeight;
  },
  methods: {
    buildNodeKey(source) {
      (source || []).forEach((o) => {
        o._id = o.type + "_" + o.id;
        this.buildNodeKey(o.children);
      });
    },
    loadInit() {
      this.$emit("input", "1");
      this.$emit("update:type", "group");
    },
    loadNode(node, resolve) {
      if (node && node.data) {
        request({
          url: "api/analy/purchase/nodeTree",
          method: "post",
          data: node.data,
        }).then((res) => {
          this.buildNodeKey(res);
          return resolve(res || []);
        });
      } else {
        request({
          url: "api/analy/purchase/nodeTree",
          method: "post",
          data: { sort: "id,desc" },
        }).then((res) => {
          this.buildNodeKey(res);
          let all = {
            children: null,
            id: "1",
            _id: "all",
            isDelete: false,
            name: "全部",
            parentId: null,
            type: "group",
            isLeaf: true,
          };
          res.unshift(all);
          this.data = res;
          setTimeout((_) => {
            this.$refs.tree && this.$refs.tree.setCurrentKey(all._id);
            // this.handleNodeClick(all, true);
          }, 200);
        });
      }
    },
    handleNodeClick(data, init = false) {
      if (data.id && data.type) {
        if (this.show) {
          this.show = false;
          this.$emit("input", data.id);
          this.$emit("update:type", data.type);
          this.$emit("change", data);
          if (init) {
            this.$emit("init");
          }
        }
      }
    },
  },
};
</script>
