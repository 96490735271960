<template>
  <div class="h5-page v">
    <filter-view class="no-flex" @submit="init">
      <div class="c h sb sep-t padding-05" @click="shop.visible = true">
        <div class="no-flex padding-05">自定义区域</div>
        <div class="flex ta-c"></div>
        <div>
          <span>{{ shop.current || "全部" }}</span>
          <i class="el-icon-arrow-right padding-05 fc-g"></i>
        </div>
      </div>
    </filter-view>

    <div class="padding-10 h c no-flex fs-small fc-g no-flex">分析结果</div>
    <div class="h" style="background-color: #ffffff; overflow: auto hidden; white-space: nowrap;" v-if="summary">
      <div class="padding-10" style="border-right: #e2e2e2 solid 1px;">
        <div class="ta-c bold">期初</div>
        <div class="h ta-c gap-1x">
          <div class="padding-0-10">
            <div class="fs-mini fc-g">数量</div>
            <div class="bold gap-small">{{summary.bopQuan.toFixed(2)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">期初库存成本</div>
            <div class="bold gap-small">{{$price(summary.bopAmount)}}</div>
          </div>
        </div>
      </div>
      <div class="padding-10" style="border-right: #e2e2e2 solid 1px;">
        <div class="ta-c bold">工厂发货</div>
        <div class="h ta-c gap-1x">
          <div class="padding-0-10">
            <div class="fs-mini fc-g">数量</div>
            <div class="bold gap-small">{{summary.deliveryQuan.toFixed(2)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">采购成本</div>
            <div class="bold gap-small">{{$price(summary.deliveryAmount)}}</div>
          </div>
        </div>
      </div>
      <div class="padding-10" style="border-right: #e2e2e2 solid 1px;">
        <div class="ta-c bold">终端销售</div>
        <div class="h ta-c gap-1x">
          <div class="padding-0-10">
            <div class="fs-mini fc-g">数量</div>
            <div class="bold gap-small">{{summary.retailQuan.toFixed(2)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">销售额</div>
            <div class="bold gap-small">{{$price(summary.retailSaleAmount)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">产品成本</div>
            <div class="bold gap-small">{{$price(summary.retailAmount)}}</div>
          </div>
        </div>
      </div>
      <div class="padding-10" style="border-right: #e2e2e2 solid 1px;">
        <div class="ta-c bold">调整</div>
        <div class="h ta-c gap-1x">
          <div class="padding-0-10">
            <div class="fs-mini fc-g">数量</div>
            <div class="bold gap-small">{{summary.changeQuan.toFixed(2)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">产品成本</div>
            <div class="bold gap-small">{{$price(summary.changeAmount)}}</div>
          </div>
        </div>
      </div>
      <div class="padding-10">
        <div class="ta-c bold">净库存</div>
        <div class="h ta-c gap-1x">
          <div class="padding-0-10">
            <div class="fs-mini fc-g">数量</div>
            <div class="bold gap-small">{{summary.balanceQuan.toFixed(2)}}</div>
          </div>
          <div class="padding-0-10">
            <div class="fs-mini fc-g">产品成本</div>
            <div class="bold gap-small">{{$price(summary.balanceAmount)}}</div>
          </div>
        </div>
      </div>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="200">
      <!-- <el-table-column type="index" width="50" /> -->
      <el-table-column prop="erpId" label="客户编码" min-width="110" fixed/>
      <el-table-column prop="name" label="客户名称" min-width="200" show-overflow-tooltip fixed/>
      <el-table-column label="期初" align="center">
        <el-table-column prop="bopQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="bopAmount" label="期初库存成本" min-width="110" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="工厂发货" align="center">
        <el-table-column prop="deliveryQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="deliveryAmount" label="采购成本" min-width="110" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="终端销售" align="center">
        <el-table-column prop="retailQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="retailAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="调整" align="center">
        <el-table-column prop="changeQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="changeAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="净库存" align="center">
        <el-table-column prop="balanceQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="balanceAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
      </el-table-column>
    </el-table>
    <el-pagination align="center" :current-page="page + 1" :page-size.sync="size" layout="prev, pager, next" class="no-flex" @current-change="pageChange" :total="total" />

    <shop-list :visible.sync="shop.visible" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" />
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import initData from "@/mixins/initData";
import shopList from "../purchaseAll/shopList";
import request from "@/utils/request";

export default {
  mixins: [initData],
  components: { filterView, shopList },
  data() {
    return {
      query: {
        treeNodeId: null,
        treeNodeType: null,
      },
      size: 20,
      shop: {
        visible: false,
        current: null,
      },
      summary: null,
    };
  },
  methods: {
    beforeInit() {
      this.url = "@host:analysis;api/analy/stockReport/distributor";
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
        },
        this.query
      );
      if (this.page === 0) this.loadSummary();
      return true;
    },
    loadSummary() {
      request({
        url: "@host:analysis;api/analy/stockReport/total",
        method: "get",
        params: this.params,
      })
        .then((res) => {
          this.summary = res;
        })
        .catch((err) => {
          this.summary = null;
        });
    },
    handleShopChange(data) {
      this.shop.current = data ? data.name : "全部";
      this.page = 0;
      this.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>