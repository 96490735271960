<template>
  <div>
    <div class="bc-w _filter" :class="{hidden: !visible}" v-loading.fullscreen.lock="loading">
      <div class="h c title no-flex" @click="visible = !visible">
        <div class="flex padding-10">分析条件</div>
        <div class="padding-10 fc-g arrow" :class="visible ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></div>
      </div>
      <div class="container flex v">
        <div class="items flex">
          <slot></slot>
        </div>
        <el-button type="primary" :loading="loading" @click="doQuery" class="no-flex" style="width: 100%;">统计</el-button>
      </div>
    </div>
    <div class="filter-opener fa fa-filter" @click="visible = true"></div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    doQuery() {
      this.visible = false;
      this.$emit("submit");
    },
  },
};
</script>
